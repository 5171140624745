import React from "react";

function AdminHome() {
  return (
    <>
      <div className="admin-home-container">
        <p>
          Welcome, <span>Admin</span>.
        </p>
      </div>
    </>
  );
}

export default AdminHome;
